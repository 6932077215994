import { NumberOperation, StringOperation } from 'types/queryOperation';
import { _Console } from 'utils/logs';

const NumberOpExecutors: Record<
  NumberOperation,
  (c: number, r: number[]) => boolean
> = {
  [NumberOperation.EQUALS]: (c: number, r: number[]) => c === r[0],
  [NumberOperation.NOT_EQUALS]: (c: number, r: number[]) => c !== r[0],
  [NumberOperation.GREATER_THAN]: (c: number, r: number[]) => c > r[0],
  [NumberOperation.GREATER_THAN_OR_EQUAL_TO]: (c: number, r: number[]) =>
    c >= r[0],
  [NumberOperation.LESS_THAN]: (c: number, r: number[]) => c < r[0],
  [NumberOperation.LESS_THAN_OR_EQUAL_TO]: (c: number, r: number[]) =>
    c <= r[0],
  [NumberOperation.BETWEEN]: (c: number, r: number[]) => c >= r[0] && c <= r[1],
  [NumberOperation.NOT_BETWEEN]: (c: number, r: number[]) =>
    c < r[0] && c > r[1],
};

const StringOpExecutors: Record<
  StringOperation,
  (c: string, r: string[]) => boolean
> = {
  [StringOperation.CONTAINS]: (c: string, r: string[]) => r.includes(c),
  [StringOperation.DOES_NOT_CONTAIN]: (c: string, r: string[]) =>
    !r.includes(c),
  [StringOperation.EQUAL]: (c: string, r: string[]) => c === r[0],
  [StringOperation.NOT_EQUAL]: (c: string, r: string[]) => c !== r[0],
  [StringOperation.IS_SET]: (c: string, r: string[]) => Boolean(c),
  [StringOperation.IS_NOT_SET]: (c: string, r: string[]) => !Boolean(c),
};

type StringExecutor = {
  operation: StringOperation;
  values: { required: string[]; current: string };
};

type NumberExecutor = {
  operation: NumberOperation;
  values: { required: number[]; current: number };
};

export function executeOperation(
  type: string,
  executor: NumberExecutor | StringExecutor,
) {
  if (type === 'number') {
    const { operation, values } = executor as NumberExecutor;
    return NumberOpExecutors[operation](values.current, values.required);
  } else if (type === 'string') {
    const { operation, values } = executor as StringExecutor;
    return StringOpExecutors[operation](values.current, values.required);
  } else {
    return false;
  }
}

export function fillCriteria(criteria: string, label: string, value: boolean) {
  return criteria.replace(label, Number(value).toString());
}

export function evaluateCriteria(criteria: string) {
  try {
    return eval(criteria); // eslint-disable-line
  } catch (error) {
    _Console.error('evaluateCriteria', error);
  }
}
