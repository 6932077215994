import axios, { AxiosInstance } from 'axios';

import { _Console } from 'utils/logs';

export class AbstractServerApi {
  private readonly axiosInstance: AxiosInstance;

  constructor(readonly baseURL: string) {
    this.axiosInstance = axios.create({ baseURL });
  }

  protected async getApi<T>(route: string) {
    try {
      const response = await this.axiosInstance.get<T>(route);
      return response;
    } catch (error) {
      _Console.error('fetch' + route, error);
    }
  }

  protected async postApi<T, U>(route: string, body?: U) {
    try {
      const response = await this.axiosInstance.post<T>(route, body);
      return response;
    } catch (error) {
      _Console.error('fetch' + route, error);
    }
  }
}
