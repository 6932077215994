import { useEffect, useRef, useMemo, useState } from 'react';
import * as DOMPurify from 'dompurify';
import { motion } from 'framer-motion';

import spockDarkLogo from 'asset/spock-dark.svg';
import spockLightLogo from 'asset/spock-light.svg';
import CrossIcon from 'components/CrossIcon';
import { MOBILE_WIDTH } from 'constants/index';
import { AppState, useAppSelector } from 'state';
import { SdkSendEvents } from 'types/events';
import { CampaignContent } from 'types/campaign';
import { sendMessage } from 'utils/sdkCommunicator';
import { getThemeMode } from 'utils/color';
import { ingestApi } from 'utils/serverApi';
import {
  RootContainer,
  CardContainer,
  BannerWrapper,
  Banner,
  BrandingWrapper,
  BrandIcon,
  BrandText,
  ContentWrapper,
  Content,
  Button,
  CloseButton,
} from './styles';

export default function Popover({
  campaign: { buttonText, campaignId, styles, message, imageUrl, redirectUrl },
  trackId,
}: {
  campaign: CampaignContent;
  trackId?: number;
}) {
  const [hide, setHide] = useState<boolean>(false);
  const { browserProps } = useAppSelector((state: AppState) => state.sdkEvent);
  const popupRef = useRef<HTMLDivElement>(null);
  const darkMode = useMemo<boolean>(
    () => getThemeMode(styles.cardBgColor) === 'dark',
    [styles.cardBgColor],
  );
  const sentizedContent = useMemo<string>(
    () => DOMPurify.sanitize(message),
    [message],
  );
  const isMobile = useMemo<boolean>(
    () => (browserProps?.innerWidth ?? 0) < MOBILE_WIDTH,
    [browserProps?.innerWidth],
  );
  const contentHeight = useMemo<string>(() => {
    return (
      (100 + (imageUrl ? 0 : 150) + (buttonText ? 0 : 50)).toString() + 'px'
    );
  }, [buttonText, imageUrl]);

  const accessoriesColor = darkMode ? '#fff' : '#121418';

  useEffect(() => {
    const height = popupRef?.current?.clientHeight;

    if (typeof height === 'number') {
      sendMessage(SdkSendEvents.SHOW_POPUP, {
        styles: isMobile ? {} : { height: `${height + 20}px` },
      });
    }
  }, [isMobile]);

  const handleButtonClick = () => {
    ingestApi.post('/campaign/action', { trackId, action: 'click' });
    sendMessage(SdkSendEvents.BUTTON_CLICK, { campaignId, redirectUrl });
  };

  const closePopup = async () => {
    ingestApi.post('/campaign/action', {
      trackId,
      action: 'close',
    });
    setHide(true);
    //deplay iframe closing until popup transaction is complete
    setTimeout(() => {
      sendMessage(SdkSendEvents.HIDE_POPUP, { campaignId });
    }, 1000 * 0.5);
  };

  return (
    <motion.div
      initial={!hide ? { x: 1000 } : { x: 0 }}
      animate={!hide ? { x: 0 } : { x: 1000 }}
      transition={{ duration: 0.5 }}
    >
      <RootContainer $isMobile={isMobile}>
        <CardContainer ref={popupRef} $backgroundColor={styles.cardBgColor}>
          <CloseButton
            $backgroundColor={styles.cardBgColor}
            onClick={closePopup}
          >
            <CrossIcon color={accessoriesColor} />
          </CloseButton>

          {imageUrl && (
            <BannerWrapper>
              <Banner src={imageUrl} />
            </BannerWrapper>
          )}

          <ContentWrapper
            $margin={imageUrl ? '0px' : '40px'}
            $isMobile={isMobile}
          >
            <Content
              $maxHeight={contentHeight}
              color={styles.textColor}
              $scrollColor={styles.btnColor}
              dangerouslySetInnerHTML={{ __html: sentizedContent }}
            ></Content>
            {buttonText && (
              <Button
                $isMobile={isMobile}
                color={styles.btnColor}
                $textColor={styles.btnTextColor}
                onClick={handleButtonClick}
              >
                {buttonText}
              </Button>
            )}

            <a
              href="https://spockanalytics.xyz/"
              target="blank"
              style={{ textDecoration: 'none' }}
            >
              <BrandingWrapper>
                <BrandText color={accessoriesColor}>Powered By</BrandText>
                <BrandIcon src={darkMode ? spockLightLogo : spockDarkLogo} />
              </BrandingWrapper>
            </a>
          </ContentWrapper>
        </CardContainer>
      </RootContainer>
    </motion.div>
  );
}
