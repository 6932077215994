import { useState, useEffect, useCallback } from 'react';

function browserSupportedEvent() {
  if ('mozHidden' in document) {
    return { eventName: 'mozvisibilitychange', eventProp: 'mozHidden' };
  } else if ('webkitHidden' in document) {
    return { eventName: 'webkitvisibilitychange', eventProp: 'webkitHidden' };
  } else if ('msHidden' in document) {
    return { eventName: 'msvisibilitychange', eventProp: 'msHidden' };
  } else {
    return { eventName: 'visibilitychange', eventProp: 'hidden' };
  }
}

const { eventName, eventProp } = browserSupportedEvent();

export default function useVisibilityChange() {
  const [hidden, setHidden] = useState<boolean>((document as any)[eventProp]);

  const eventHandler = useCallback(() => {
    setHidden((document as any)[eventProp]);
  }, []);

  useEffect(() => {
    document.addEventListener(eventName, eventHandler);

    return () => document.removeEventListener(eventName, eventHandler);
  }, [eventHandler]);

  return hidden;
}
