export function getThemeMode(givenColor: string) {
  // Convert the given color to RGB format
  const color = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(givenColor);

  if (!color) {
    return null; // Invalid color format
  }

  // Extract the RGB values
  const red = parseInt(color[1], 16);
  const green = parseInt(color[2], 16);
  const blue = parseInt(color[3], 16);

  // Calculate luminance to determine if the color is closer to black or white
  const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  return luminance > 0.5 ? 'light' : 'dark';
}
