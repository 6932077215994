import { useEffect, useCallback } from 'react';

import { useAppDispatch } from 'state';
import { walletConnect, transaction } from 'state/reducers/sdkEvent';
import { SdkReceiveEvents, SdkEvent } from 'types/events';
import { geolocationApi } from 'utils/serverApi';

export default function useSdkEventListener() {
  const dispatch = useAppDispatch();

  const eventHandler = useCallback(
    async ({ data }: MessageEvent<SdkEvent>) => {
      switch (data.message) {
        case SdkReceiveEvents.WALLET_CONNECT:
          const {
            chain,
            distinctId,
            libVersion,
            walletAddress,
            utmCampaign,
            utmContent,
            utmMedium,
            utmSource,
            utmTerm,
            store,
            browserProps,
            sessionId,
            currentUrl,
            browser,
            device,
            os,
          } = data.body;

          const location = await geolocationApi.get<{
            city: string;
            country_name: string;
            region: string;
          }>(`/${store.ip}/json`);

          dispatch(
            walletConnect({
              chain,
              distinctId,
              libVersion,
              walletAddress,
              utmCampaign,
              utmContent,
              utmMedium,
              utmSource,
              utmTerm,
              store,
              sessionId,
              currentUrl,
              browserProps,
              browser,
              device,
              os,
              city: location?.city,
              country: location?.country_name,
              region: location?.region,
            }),
          );
          break;
        case SdkReceiveEvents.TRANSACTION:
          dispatch(transaction({ store: data.body?.store }));
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener('message', eventHandler);

    return () => window.removeEventListener('message', eventHandler);
  }, [eventHandler]);
}
