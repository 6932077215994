import { INGEST_SERVER } from 'constants/index';
import { AbstractServerApi } from 'classes/abstractServer';
import { _Console } from 'utils/logs';

export interface ServerResponse<T> {
  statusCode: number;
  message: string;
  result: T;
}

class ServerApi extends AbstractServerApi {
  constructor(readonly baseURL: string) {
    super(baseURL);
  }

  async get<T>(route: string): Promise<T | undefined> {
    const res = await this.getApi<ServerResponse<T>>(route);
    if (res) {
      const { data } = res;
      if (data.message === 'Success') {
        return data.result;
      } else {
        _Console.error('res' + route, data.message);
      }
    }
  }

  async post<T>(route: string, body: any): Promise<T | undefined> {
    const res = await this.postApi<ServerResponse<T>, any>(route, body);
    if (res) {
      const { data } = res;
      if (data.message === 'Success') {
        return data.result;
      } else {
        _Console.error('res' + route, data.message);
      }
    }
  }
}

class ThirdPartyApi extends AbstractServerApi {
  constructor(readonly baseURL: string) {
    super(baseURL);
  }

  async get<T>(route: string): Promise<T | undefined> {
    return (await this.getApi<T>(route))?.data;
  }
}

export const ingestApi = new ServerApi(INGEST_SERVER);

export const geolocationApi = new ThirdPartyApi('https://ipapi.co');
