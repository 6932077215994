import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UTM_Params } from 'types/general';
import { SdkStore } from 'types/events';
import { spreadState } from 'utils/helpers';

export interface SdkState extends UTM_Params {
  readonly chain?: number;
  readonly currentUrl?: number;
  readonly libVersion?: string;
  readonly sessionId?: string;
  readonly walletAddress?: string;
  readonly distinctId?: string;
  readonly store: SdkStore;
  readonly browserProps?: { innerWidth: number };
  readonly browser: string;
  readonly device: string;
  readonly os: string;
  readonly city?: string;
  readonly country?: string;
  readonly region?: string;
}

const initialState: SdkState = {
  store: { duration: 0 } as SdkStore,
  browser: '',
  device: '',
  os: '',
};

const sdkEventSlice = createSlice({
  name: 'sdkEvent',
  initialState,
  reducers: {
    walletConnect(state, { payload }: PayloadAction<SdkState>) {
      spreadState(state, payload);
    },
    transaction(state, { payload }: PayloadAction<{ store: SdkStore }>) {
      state.store = payload.store;
    },
    durationIncrement(state, { payload }: PayloadAction<number>) {
      state.store.duration += payload;
    },
  },
});

export const { walletConnect, transaction, durationIncrement } =
  sdkEventSlice.actions;
export default sdkEventSlice.reducer;
