import styled from 'styled-components';

export const RootContainer = styled('div')<{ $isMobile: boolean }>(
  ({ $isMobile }) =>
    $isMobile
      ? {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          postion: 'relative',
          backdropFilter: 'blur(5px)',
        }
      : null,
);

export const CardContainer = styled('div')<{ $backgroundColor: string }>(
  ({ $backgroundColor }) => ({
    width: '330px',
    maxHeight: '400px',
    borderRadius: '6px',
    overflow: 'hidden',
    fontFamily: 'Arial',
    boxShadow: '-1px 1px 11px 3px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: '-1px 1px 11px 3px rgba(0, 0, 0, 0.2)',
    MozBoxShadow: '-1px 1px 11px 3px rgba(0, 0, 0, 0.2)',
    backgroundColor: $backgroundColor,
    position: 'relative',
    zIndex: 1,
    margin: '10px',
  }),
);

export const BannerWrapper = styled('div')(() => ({
  width: '100%',
  height: '200px',
  borderRadius: '6px',
}));

export const Banner = styled('img')(() => ({
  height: '100%',
  width: '100%',
  aspectRatio: '1.77 / 1',
}));

export const BrandingWrapper = styled('div')(() => ({
  marginTop: '10px',
}));

export const BrandIcon = styled('img')(() => ({
  width: '60px',
  height: '10px',
}));

export const BrandText = styled('span')<{ color: string }>(({ color }) => ({
  fontSize: '10px',
  color,
}));

export const ContentWrapper = styled('div')<{
  $margin: string;
  $isMobile: boolean;
}>(({ $margin, $isMobile }) => ({
  height: '50%',
  padding: '10px 14px',
  marginTop: $margin,
  textAlign: $isMobile ? 'center' : 'left',
}));

export const Content = styled('div')<{
  color: string;
  $scrollColor: string;
  $maxHeight: string;
}>(({ color, $scrollColor, $maxHeight }) => ({
  maxHeight: $maxHeight,
  overflowY: 'auto',

  '& p': {
    margin: '8px 0',
    fontSize: '12px',
    lineHeight: '15px',
    color,
  },

  '&::-webkit-scrollbar': {
    width: '5px',
    height: '5px',
  },

  '&::-webkit-scrollbar-track ': {
    borderRadius: '5px',
    background: `${$scrollColor}30`,
  },

  '&::-webkit-scrollbar-thumb': {
    background: $scrollColor,
    borderRadius: '5px',
  },
}));

export const Button = styled('button')<{
  color: string;
  $textColor: string;
  $isMobile: boolean;
}>(({ color, $textColor, $isMobile }) => ({
  background: color,
  color: $textColor,
  cursor: 'pointer',
  border: 'none',
  padding: '12px 40px',
  borderRadius: '4px',
  outline: 'none',
  fontWeight: '600px',
  display: 'block',
  margin: $isMobile ? '10px auto 0 auto' : '10px 0 0 0',
}));

export const CloseButton = styled('div')<{ $backgroundColor: string }>(
  ({ $backgroundColor }) => ({
    backgroundColor: $backgroundColor,
    width: '22px',
    height: '22px',
    position: 'absolute',
    top: '10px',
    right: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    borderRadius: '3px',
    cursor: 'pointer',
  }),
);
