import { UTM_Params } from 'types/general';

export enum SdkSendEvents {
  SHOW_POPUP = 'spock_show_popup',
  HIDE_POPUP = 'spock_hide_popup',
  BUTTON_CLICK = 'spock_button_click',
}

export enum SdkReceiveEvents {
  WALLET_CONNECT = 'spock_wallet_connect',
  TRANSACTION = 'spock_transaction',
}

export interface SdkStore {
  txnReject: number;
  txnSubmit: number;
  ipAddress?: string;
  optOut: boolean;
  initialized: boolean;
  flow: object[];
  duration: number;
}

export interface MetaData extends UTM_Params {
  appKey: string;
  browser?: string;
  browserVersion?: number;
  chain?: number;
  currentUrl?: string;
  device?: string;
  distinctId: string;
  ip: string;
  insertId: string;
  libVersion: string;
  os?: string;
  referrer?: string;
  referringDomain?: string;
  screenHeight?: number;
  screenWidth?: number;
  searchEngine?: string;
  sessionId?: string;
  time: number;
  walletAddress?: string;
  store: SdkStore;
  browserProps: { innerWidth: number };
}

export interface WalletConnectEvent extends MetaData {
  walletType: string;
}

export enum TransactionStats {
  REJECTED = 0,
  SUBMITTED = 1,
  PENDING = 2,
  FAILED = 3,
}

export interface TransactionEvent extends MetaData {
  from: string;
  gasPrice?: string;
  hash: string;
  maxFeePerGas?: string;
  status: TransactionStats;
  to: string;
}

export type SdkEvent = {
  message: SdkReceiveEvents;
  body: any;
};
