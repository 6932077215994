export default function CrossIcon({ color }: { color: string }) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.697 1.76621C15.793 1.67026 15.8693 1.55632 15.9213 1.4309C15.9734 1.30548 16.0002 1.17104 16.0003 1.03526C16.0003 0.899474 15.9737 0.765002 15.9218 0.639521C15.8699 0.514039 15.7938 0.400006 15.6979 0.303933C15.6019 0.207859 15.488 0.131626 15.3626 0.0795864C15.2371 0.0275465 15.1027 0.000719014 14.9669 0.000635556C14.8311 0.000552099 14.6967 0.0272143 14.5712 0.0791C14.4457 0.130986 14.3317 0.207078 14.2356 0.303034L8 6.53862L1.76621 0.303034C1.57218 0.109004 1.30902 -2.04443e-09 1.03462 0C0.760224 2.04443e-09 0.497064 0.109004 0.303034 0.303034C0.109004 0.497063 2.04443e-09 0.760224 0 1.03462C-2.04443e-09 1.30902 0.109004 1.57218 0.303034 1.76621L6.53862 8L0.303034 14.2338C0.20696 14.3299 0.130751 14.4439 0.0787559 14.5694C0.0267612 14.695 0 14.8295 0 14.9654C0 15.1012 0.0267612 15.2358 0.0787559 15.3613C0.130751 15.4868 0.20696 15.6009 0.303034 15.697C0.497064 15.891 0.760224 16 1.03462 16C1.17049 16 1.30503 15.9732 1.43056 15.9212C1.55608 15.8692 1.67014 15.793 1.76621 15.697L8 9.46138L14.2356 15.697C14.4296 15.8908 14.6927 15.9995 14.9669 15.9994C15.2411 15.9992 15.5041 15.8901 15.6979 15.6961C15.8917 15.502 16.0004 15.239 16.0003 14.9647C16.0001 14.6905 15.891 14.4276 15.697 14.2338L9.46138 8L15.697 1.76621Z"
        fill={color}
      />
    </svg>
  );
}
