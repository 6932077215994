import { useCallback, useEffect, useMemo, useState } from 'react';

import { MOBILE_WIDTH } from 'constants/index';
import useAppKey from 'hooks/useAppKey';
import { AppState, useAppSelector, useAppDispatch } from 'state';
import { durationIncrement } from 'state/reducers/sdkEvent';
import { SdkSendEvents } from 'types/events';
import { CampaignContent, CampaignParameter } from 'types/campaign';
import { sendMessage } from 'utils/sdkCommunicator';
import { ingestApi } from 'utils/serverApi';
import {
  executeOperation,
  fillCriteria,
  evaluateCriteria,
} from 'utils/queryEvaluation';

export interface CampaignData {
  content?: CampaignContent;
  parameters?: CampaignParameter[];
  criteria?: string;
  polling?: boolean;
  sufficientBalance: boolean;
}

const iframeStyles = {
  desktop: {
    display: 'block',
    bottom: '2%',
    right: '2%',
    width: '350px',
  },
  mobile: {
    display: 'block',
    margin: '0',
    bottom: '0',
    right: '0',
    width: '100%',
    height: '100%',
  },
};

export default function useCampaign() {
  const appKey = useAppKey();
  const {
    chain,
    walletAddress,
    sessionId,
    distinctId,
    currentUrl,
    browserProps,
    country,
    city,
    region,
  } = useAppSelector((state: AppState) => state.sdkEvent);

  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
  const [resolvedCampaign, setResolvedCampaign] = useState<CampaignData>();
  const [showCampaign, setShowCampaign] = useState<boolean>(false);
  const [trackId, setTrackId] = useState<number>();

  const renderCampaign = useCallback(
    async (campaignId: number, sufficientBalance: boolean) => {
      //count the missed popups
      if (!sufficientBalance) {
        await ingestApi.get(`/campaign/missed-popup/${campaignId}`);
        return;
      }

      const width = browserProps?.innerWidth ?? 0;

      const id = await ingestApi.post<number>('/campaign/serve-popup', {
        appKey,
        currentUrl,
        chain,
        distinctId,
        sessionId,
        time: Date.now() / 1000,
        walletAddress,
        campaignId,
        country,
        city,
        region,
      });

      setTrackId(id);

      setTimeout(() => {
        setShowCampaign(true);
      }, 1000 * 2);

      sendMessage(SdkSendEvents.SHOW_POPUP, {
        styles:
          width < MOBILE_WIDTH ? iframeStyles.mobile : iframeStyles.desktop,
        campaignId,
      });
    },
    [
      appKey,
      chain,
      currentUrl,
      distinctId,
      sessionId,
      walletAddress,
      browserProps?.innerWidth,
      country,
      city,
      region,
    ],
  );

  const fetchCampaign = useCallback(async () => {
    if (appKey && chain && walletAddress) {
      const campaignData = await ingestApi.post<CampaignData[]>(
        '/campaign/wallet-connect',
        {
          appKey,
          chain,
          walletAddress,
        },
      );

      //hide if dialog already there for wallet change scenario
      setShowCampaign(false);
      setCampaigns(campaignData ?? []);

      const resolvedCampaign = campaignData?.find(
        (campaign) => campaign.content && !campaign?.parameters,
      );

      setResolvedCampaign(resolvedCampaign);
    }
  }, [appKey, chain, walletAddress]);

  useCriteriaCheck(setResolvedCampaign, showCampaign, campaigns);

  useEffect(() => {
    fetchCampaign();
  }, [fetchCampaign]);

  useEffect(() => {
    resolvedCampaign?.content &&
      renderCampaign(
        resolvedCampaign.content.campaignId,
        resolvedCampaign.sufficientBalance,
      );
  }, [resolvedCampaign, renderCampaign]);

  return {
    campaign: showCampaign ? resolvedCampaign?.content : undefined,
    trackId,
  };
}

function useCriteriaCheck(
  setResolvedCampaign: React.Dispatch<
    React.SetStateAction<CampaignData | undefined>
  >,
  showCampaign: boolean,
  campaignData: CampaignData[],
) {
  const {
    utmCampaign,
    browser,
    device,
    os,
    country,
    city,
    region,
    store: { duration, txnReject, txnSubmit },
    chain: connectedChain,
  } = useAppSelector((state: AppState) => state.sdkEvent);
  const dispatch = useAppDispatch();
  const polling = useMemo<boolean>(
    () => Boolean(campaignData?.find((campaign) => campaign.polling)),
    [campaignData],
  );

  const evaluate = useCallback(() => {
    const currentValues: Record<string, number | string> = {
      session_rejected_txn_count: txnReject,
      session_txn_count: txnSubmit,
      session_time: duration,
      session_utm_campaigns: utmCampaign ?? '',
      session_browser: browser,
      session_city: city ?? '',
      session_country: country ?? '',
      session_region: region ?? '',
      session_device: device,
      session_os: os,
    };

    for (let i = 0; i < campaignData.length; i++) {
      const { content, criteria, parameters } = campaignData[i];

      if (content && criteria && parameters) {
        const results = parameters.reduce<Record<string, boolean>>(
          (
            accum,
            { label, chain, args: { operation, value, parameter, type } },
          ) => {
            if (chain !== connectedChain) {
              accum[label] = false;
            } else {
              accum[label] = executeOperation(type, {
                operation,
                values: {
                  required: value,
                  current: currentValues[parameter],
                } as any,
              });
            }
            return accum;
          },
          {},
        );

        const filledCriteria = Object.entries(results).reduce(
          (accum, [label, value]) => {
            accum = fillCriteria(accum, label, value);
            return accum;
          },
          criteria,
        );

        if (evaluateCriteria(filledCriteria)) {
          setResolvedCampaign(campaignData[i]);
          break;
        }
      }
    }
  }, [
    campaignData,
    duration,
    txnReject,
    txnSubmit,
    connectedChain,
    utmCampaign,
    browser,
    device,
    os,
    city,
    country,
    region,
    setResolvedCampaign,
  ]);

  useEffect(() => {
    if (!showCampaign) {
      evaluate();
    }
  }, [showCampaign, evaluate]);

  useEffect(() => {
    let durationPolling: NodeJS.Timer;

    if (polling && !showCampaign) {
      durationPolling = setInterval(() => {
        dispatch(durationIncrement(30));
      }, 30 * 1000);
    }

    return () => clearInterval(durationPolling);
  }, [polling, showCampaign, dispatch]);
}
