export const DEV_ENV = process.env.REACT_APP_ENV === 'dev';

// const INGEST_TEST_SERVER = 'https://ingest-dev.spockanalytics.xyz';

const INGEST_PROD_SERVER = 'https://ingest.spockanalytics.xyz';

// export const INGEST_SERVER = DEV_ENV ? INGEST_TEST_SERVER : INGEST_PROD_SERVER;

export const INGEST_SERVER = INGEST_PROD_SERVER;

export const MOBILE_WIDTH = 768;
