export enum NumberOperation {
  EQUALS = 'equals',
  NOT_EQUALS = 'not equals',
  GREATER_THAN = 'greater than',
  GREATER_THAN_OR_EQUAL_TO = 'greater than or equal to',
  LESS_THAN = 'less than',
  LESS_THAN_OR_EQUAL_TO = 'less than or equal to',
  BETWEEN = 'between',
  NOT_BETWEEN = 'not between',
}

export enum StringOperation {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'does not contain',
  EQUAL = 'equal',
  NOT_EQUAL = 'not equal',
  IS_SET = 'is set',
  IS_NOT_SET = 'is not set',
}
