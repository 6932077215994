import Popover from 'components/Popover';
import useCampaign from 'hooks/useCampaign';
import useSdkEventListener from 'hooks/useSdkEventListener';
import useVisibilityChange from 'hooks/useVisibilityChange';

function App() {
  useSdkEventListener();

  const { campaign, trackId } = useCampaign();
  const hidden = useVisibilityChange();

  return campaign && !hidden ? (
    <Popover campaign={campaign} trackId={trackId} />
  ) : null;
}

export default App;
