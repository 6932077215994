import { DEV_ENV } from 'constants/index';

export class _Console {
  static log(message: string, ...params: any) {
    if (DEV_ENV) {
      console.log(message, ' -> ', ...params);
    }
  }

  static error(message: string, ...params: any) {
    if (DEV_ENV) {
      console.error(message, ' -> ', ...params);
    }
  }

  static debug(message: string, ...params: any) {
    if (DEV_ENV) {
      console.debug(message, ' -> ', ...params);
    }
  }
}
